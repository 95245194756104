import { useState } from "react";
import { HidePassword, ShowPassword } from "../../svg";

interface InputFieldProps {
    labelName?: string;
    type: string;
    id: string;
    isRequired?: boolean;
    value?: string;
    formHandle: any;
    isReadOnly?: boolean;
    error: any;
    placeholder?: string;
    inputRef?: React.Ref<HTMLInputElement>;
    handleOnBlur?:any
}

export const InputField = ({
    labelName,
    type,
    formHandle,
    id,
    isRequired,
    isReadOnly,
    value,
    error
}: InputFieldProps) => {
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <div className="relative">
            <input
                type={type === "password" ? (showPassword ? "text" : "password") : type}
                {...formHandle}
                name={id}
                readOnly={isReadOnly}
                className="bg-transparent py-[1.2rem] font-onest text-[1.8rem] peer block w-full appearance-none border-b border-[#B4B4B4] focus:border-ft-primary focus:outline-none focus:ring-0"
                placeholder=" "
                value={value}
            />
            {labelName && (
                <label className="absolute top-5 block origin-[0] left-0 text-aimagix-gray-66 -translate-y-12 transform text-[2rem] duration-300 peer-placeholder-shown:translate-y-0 peer-focus:left-0 peer-focus:-translate-y-12 pointer-events-none">
                    {labelName} {isRequired && <span className="text-red-500">*</span>}
                </label>
            )}
            {type === "password" && (
                <button
                    type="button"
                    className="w-[2.1rem] h-auto absolute right-4 top-[50%] translate-y-[-50%]"
                    onClick={togglePasswordVisibility}
                >
                    {showPassword ? <ShowPassword /> : <HidePassword />}
                </button>

            )}
            {error && (
                <p className="text-[1.4rem] font-onest font-normal text-aimagix-red text-left absolute top-full left-0">
                    {error}
                </p>
            )}
        </div>
    )
};