function WhyInspectionOne(){
    const whyInspectionOne = [
        "Achieve ROI within 3 months of installing Inspection ONE. ",
        "Plug into your existing process with minimal upfront investment ",
        "Integrates seamlessly into your existing workflow, ensuring disruption-free operations",
        "Achieve 80-85% accuracy in defect detection with high-precision AI technology.",
        "Enjoy effortless setup with SDK and API integration—no training needed for your IT team "
    ]
    return(
        <div className="w-full h-auto common-y-space relative triangle-pattern-right max-xs:py-[5%]">
            <div className="w-[76%] h-auto mx-auto max-xs:w-[90%]">
                <div className="w-full h-auto flex justify-center items-center gap-[10rem] max-xs:flex-col-reverse">
                    <div className="w-[59.4rem] h-auto max-xs:w-[90%]">
                        <div className="w-full h-auto">
                            <img src={require("../../../assets/images/why-inspection-one.png")} alt="Computer Vision" />
                        </div>
                    </div>
                    <div className="flex-1">
                        <div className="w-full ">
                            <h2 className="text-[4.5rem] font-onest font-normal mb-[2rem] leading-tight"><span className="font-medium text-transparent bg-clip-text bg-gradient-to-r from-[#754eff] to-[#ff7a00]">Why Inspection ONE</span> is a <br /> Game - Changer for Your Business  </h2>
                           <div className="w-full h-auto">
                                {whyInspectionOne.map((item, index) => (
                                    <div key={index} className="w-full h-auto py-[2.6rem] border-b border-[#E4E4E4] border-opacity-50">
                                        <div className="w-full flex justify-start items-center gap-[2.3rem]">
                                            <div className="w-[5.6rem] h-[5.6rem]">
                                            <div className="w-[5.6rem] h-[5.6rem] bg-[#F5F9DE] rounded-[0.6rem] flex justify-center items-center">
                                                <div className="w-[3.1rem] h-auto">
                                                <img src={require("../../../assets/images/green-tick.png")} alt="green tick" />
                                                </div>
                                            </div>
                                            </div>
                                            
                                            <p className="text-[2.2rem] font-onest font-normal text-aimagix-gray-66 ">{item}</p>
                                        </div>
                                    </div>
                                ))}
                           </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default WhyInspectionOne;