import AutomotiveManufacturing from "../components/section/automotive-manufacturing/automotive-manufacturing";
import Banner from "../components/section/banner/banner";
import ClientFeedback from "../components/section/client-feedback/client-feedback";
import ComputerVision from "../components/section/computer-vision/computer-vision";
import GetStarted from "../components/section/get-started/get-started";
import LeadingBrands from "../components/section/leading-brands/leading-brands";
import ManufacturingSOP from "../components/section/manufacturing-sop/manufacturing-sop";
import SeamlessPrecision from "../components/section/seamless-precision/seamless-precision";
import WhyInspectionOne from "../components/section/why-inspection-one/why-inspection-one";

function Home({handleOpenBookademo}:any){
    return(
        <div className="w-full h-auto">
            <Banner handleOpenBookademo={handleOpenBookademo} />
            <ComputerVision />
            <AutomotiveManufacturing/>
            <WhyInspectionOne />
            <SeamlessPrecision />
            <ManufacturingSOP />
            <LeadingBrands />
            <ClientFeedback />
            
        </div>
    )
}
export default Home;