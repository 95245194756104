import { Outlet, useLocation } from "react-router-dom";
import Header from "../components/global-layouts/header/header";
import Footer from "../components/global-layouts/footer/footer";
import { useEffect, useState } from "react";
import BookADemo from "./book-a-demo";
import GetStarted from "../components/section/get-started/get-started";

function Layout({ isBookFromApp }: any) {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
    const [isBookADemofromHome, setIsBookADemoFromHome] = useState(true)
    const [bookademoModal, setBookademoModal] = useState(false);

    useEffect(() => {
        if (bookademoModal) {
            document.body.classList.add("overflow-y-hidden")
        } else {
            document.body.classList.remove("overflow-y-hidden")
        }
    }, [bookademoModal])

    useEffect(() => {
        setBookademoModal(true)
    }, [isBookFromApp])

    useEffect(() => {
        const mediaQuery = window.matchMedia("(max-width: 768px)");

        const handleScreenChange = (e: any) => {
            if (e.matches) {
                setBookademoModal(true);
            } else {
                setBookademoModal(false);
            }
        };

        handleScreenChange(mediaQuery);

        mediaQuery.addEventListener("change", handleScreenChange);

        return () => {
            mediaQuery.removeEventListener("change", handleScreenChange);
        };
    }, []);

    return (
        <div className="w-full h-auto relative">
            <Header handleOpenBookaDemo={() => setBookademoModal(true)} />
            <Outlet />
            <GetStarted handleOpenBookademo={() => setBookademoModal(true)} />
            <Footer />
            {bookademoModal && <div className="w-full h-auto fixed top-0 left-0 z-[999]">
                <BookADemo isBookADemofromHome={isBookADemofromHome} handleCloseModal={() => setBookademoModal(false)} />
            </div>}

        </div>
    )
}
export default Layout;