import { useNavigate } from "react-router-dom";

function Banner({handleOpenBookademo}:any) {
    const navigate = useNavigate()
    return (
        <div className="w-full h-screen max-xs:h-[100dvh] relative">
            <div className="w-full h-full banner-bg banner-left-gradient banner-right-gradient">
                <div className="w-full h-full flex justify-center items-end">
                    <div className="w-full">
                        <div className="w-[80%] max-xs:w-[85%] mx-auto text-center mb-[17rem] max-xs:mb-[15%]">
                            <h1 className="text-[7rem] max-xs:text-[4.5rem] font-onest font-normal text-[#1D2B36] leading-snug text-center">The Only SaaS Platform Built for <br /> Every Segment of the</h1>
                            <h1 className="text-[7rem] max-xs:text-[4.5rem] font-onest font-bold text-transparent bg-clip-text bg-gradient-to-r from-[#754eff] to-[#ff7a00] text-center relative banner-star inline-block mx-auto mb-[5rem]">Automobile Manufacturing Ecosystem </h1>
                            <div className="w-full flex justify-center">
                                <button type="button" onClick={handleOpenBookademo} className="w-[25rem] flex justify-center items-center border border-aimagix-primary rounded-full text-[1.8rem] font-onest font-normal text-aimagix-white primary-gradient px-[1rem] py-[1rem] leading-tight">
                                    Book a Demo
                                </button>
                            </div>

                        </div>
                        <div className="w-[85%] mx-auto mb-[2.5rem] text-center">
                            <p className="text-[2rem] font-onest font-normal text-[#D4D4D4]">Scroll Down</p>
                            <div className="w-[2px] mx-auto h-[7rem] bg-[#B5B5B5] rounded-full relative overflow-hidden">
                                <div className="w-[2px] h-[4rem] bg-[#848484] absolute top-0 left-0 animate-lineSlide">
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    )
}
export default Banner;