interface CardProps {
    imageUrl: string;
    label: string;
    rotateClass?: string;
}

function AutomotiveManufacturing() {
    const cards = [
        {
            imageUrl: "landscape-1.png",
            label: "Auto Component OEMs",
            rotateClass: "-rotate-[9deg] origin-bottom-right max-xs:rotate-0",
        },
        {
            imageUrl: "landscape-2.png",
            label: "Auto OEMs",
            rotateClass: "",
        },
        {
            imageUrl: "landscape-3.png",
            label: "Auto Logistics Partners",
            rotateClass: "rotate-[9deg] origin-bottom-left max-xs:rotate-0",
        },
    ];
    return (
        <div className="w-full h-auto common-y-space">
            <div className="w-[76%] h-auto mx-auto max-xs:w-[90%]">
                <div className="w-full h-auto">
                    <h2 className="text-[4.6rem] max-xs:text-[3rem] font-onest font-medium text-aimagix-dark-1d text-center mb-[8rem]">Transforming Quality Across the<br /> <span className=" text-transparent bg-clip-text bg-gradient-to-r from-[#754eff] to-[#ff7a00] ">Automotive Manufacturing Landscape </span></h2>
                </div>  
            </div>
            <div className="w-full h-auto">
                <div className="w-[80%] mx-auto">
                    <div className="w-full grid grid-cols-3 gap-[3.2rem] max-xs:grid-cols-1">
                        {cards.map((card, index) => (
                            <Card
                                key={index}
                                imageUrl={card.imageUrl}
                                label={card.label}
                                rotateClass={card.rotateClass}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}


const Card: React.FC<CardProps> = ({ imageUrl, label, rotateClass }) => {
    return (

        <div className={`w-full transform transition duration-300 ease-in-out rounded-[2.5rem] shadow-[0px_0px_12.52px_0px_#4467ED1F] hover:shadow-[0px_3.91px_16.14px_0px_#5C4AF636] overflow-hidden ${rotateClass}`}>
            <div className="w-full h-auto group p-[1.5rem]">
                <div className="w-full h-auto transition duration-300  grayscale group-hover:grayscale-0 max-xs:grayscale-0">
                    <img src={require(`../../../assets/images/${imageUrl}`)} />
                </div>
                <div className="w-full text-center pt-[3.9rem] pb-[5rem]">
                        <p className="text-[3.3rem] font-onest font-normal text-aimagix-dark-1d transition duration-300  group-hover:text-aimagix-primary group-hover:font-semibold">{label}</p>
                </div>
            </div>
        </div>
    );
};

export default AutomotiveManufacturing;