import { useNavigate } from "react-router-dom";

function GetStarted({handleOpenBookademo}:any){
    const navigate = useNavigate()
    return(
        <div className="w-full h-auto get-started-bg">
            <div className="w-full h-auto get-started-pattern py-[7.3rem]">
                <div className="w-[76%] h-auto mx-auto max-xs:w-[90%]">
                    <div className="w-full h-auto flex justify-between items-center max-xs:flex-col ">
                        <div className="w-auto">
                            <p className="text-[4rem] max-xs:text-[3rem] font-normal font-onest text-aimagix-white max-xs:text-center">See how Inspection ONE helps <br /> you achieve ROI in just</p>
                            <p className="text-[7rem] max-xs:text-[6rem] font-semibold font-onest text-aimagix-white max-xs:text-center">3 months</p>
                        </div>
                        <div className="w-auto max-xs:mt-[3rem]">
                        <button type="button" onClick={handleOpenBookademo} className="w-[26rem] flex justify-center items-center border border-aimagix-white rounded-full text-[2.4rem] font-onest font-semibold text-aimagix-primary bg-aimagix-white px-[1rem] py-[1rem] leading-tight">
                            Get Started
                            </button>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}
export default GetStarted;