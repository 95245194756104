function ComputerVision(){
    return(
        <div className="w-full h-auto common-y-space relative triangle-pattern-left bg-[#FBFAFF]">
            <div className="w-[76%] max-xs:w-[90%] h-auto mx-auto">
                <div className="w-full h-auto flex justify-center items-center gap-[10rem] max-xs:flex-col-reverse">
                    <div className="flex-1">
                        <div className="w-full ">
                            <h2 className="text-[4.5rem] font-onest font-normal mb-[2rem]">A new paradigm in inspections <br /> driven by <span className="font-medium text-transparent bg-clip-text bg-gradient-to-r from-[#754eff] to-[#ff7a00]">Computer Vision</span></h2>
                            <p className="text-[1.8rem] font-dmsans font-normal text-aimagix-gray-66 mb-[2rem]"><span className="text-aimagix-dark">Introducing Inspection ONE — </span>a cutting-edge platform revolutionizing automobile and component inspections with state-of-the-art Industry 4.0 technology. Powered by advanced Computer Vision and Generative AI, Inspection ONE delivers unmatched accuracy and efficiency across the entire vehicle and auto component manufacturing spectrum. Our platform guarantees the highest standards in quality control, making damage and defect detection effortless across all segments of automotive production. From complete vehicle inspections to individual components and accessories, Inspection ONE is your trusted solution for precision, consistency, and reliability in automotive manufacturing.</p>
                            <p className="text-[1.8rem] font-dmsans font-normal text-aimagix-gray-66 "><span className="text-aimagix-dark">Discover the Future of AI-Powered Inspections </span>with Inspection ONE—the all-encompassing platform that’s setting new standards in automotive quality assurance. </p>
                        </div>
                    </div>
                    <div className="w-[59.4rem] h-auto max-xs:w-[90%]">
                        <div className="w-full h-auto">
                            <img src={require("../../../assets/images/computer-vision.png")} alt="Computer Vision" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ComputerVision;