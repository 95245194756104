
function ManufacturingSOP(){
    
    return(
        <div className="w-full h-auto py-[14rem] max-xs:py-[5%] relative manufacturing-right-gradient">
            <div className="w-[76%] h-auto mx-auto max-xs:w-[90%]">
                <div className="w-full h-auto">
                    <h2 className="text-[4.6rem] max-xs:text-[4rem] font-onest font-medium text-aimagix-dark-1d text-center mb-[8rem]">Achieve Unparalleled Precision in Every Step <br /> of Your <span className=" text-transparent bg-clip-text bg-gradient-to-r from-[#754eff] to-[#ff7a00] ">Manufacturing SOP</span></h2>
                    <div className="w-[132rem] h-auto mx-auto max-xs:w-[90%]">
                        <img src={require("../../../assets/images/manufacturing-sop.png")} alt="Manufacturing SOP" />
                    </div>
                </div>

            </div>
        </div>
    )
}
export default ManufacturingSOP;