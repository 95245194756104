function LeadingBrands(){
    const customers = [
        "client-1.png",
        "client-2.png",
        "client-3.png",
        "client-4.png",
        "client-5.png",
        "client-6.png",
        "client-7.png",
        "client-8.png",
        "client-9.png",
        "client-10.png",
        "client-11.png",
        "client-12.png",
        "client-13.png",
        "client-14.png",
        "client-15.png",
        "client-16.png",
        "client-17.png",
        "client-18.png",
        "client-19.png",
        "client-20.png",
    ]
    return(
        <div className="w-full h-auto pt-[8.5rem] pb-[5.5rem] bg-[#FBFAFF]">
            <div className="w-[76%] h-auto mx-auto max-xs:w-[90%]">
                <div className="w-full h-auto">
                    <h2 className="text-[4.6rem] max-xs:text-[4rem] font-onest font-medium text-aimagix-dark-1d text-center mb-[6rem]">Trusted by Leading brands in the <br /> <span className=" text-transparent bg-clip-text bg-gradient-to-r from-[#754eff] to-[#ff7a00] ">Auto OEM and Auto component OEM industry </span></h2>
                </div>  
              
            </div>
            <div className="w-full h-auto px-[10.1rem] max-xs:px-0 max-xs:w-[90%] max-xs:mx-auto">
        <div className="w-full h-auto">
        <div className="grid grid-cols-5 gap-0 max-xs:grid-cols-2">
                        {customers.map((customer, index) => (
                            <div
                                key={index}
                                className={`w-full h-auto p-[5rem] ${
                                    index < 15 ? 'border-b' : ''
                                } ${index % 5 !== 4 ? 'border-r' : ''} max-xs:border`}
                            >
                                <div className="w-[20rem] h-[10rem] mx-auto max-xs:w-[30vw] max-xs:h-auto">
                                    <img src={require(`../../../assets/images/${customer}`)} alt="" />
                                </div>
                            </div>
                        ))}
                    </div>
        </div>
</div>

        </div>
    )
}
export default LeadingBrands;