import { useEffect } from "react";
import { useLocation } from "react-router-dom";

function PrivacyPolicy(){
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return(
        <div className="w-full h-auto ">
            <div className="w-full h-auto pt-[10%] max-xs:pt-[25%] relative triangle-pattern-privacy">
                <div className="w-[50%] max-xs:w-[90%] h-auto text-center mx-auto mb-[5.7rem]">
                    <h1 className="text-[8rem] max-xs:text-[5rem] font-onest font-normal text-aimagix-dark">Privacy Policy</h1>
                    <p className="text-[1.8rem] font-dmsans font-normal text-aimagix-gray-66">Welcome to AI Magix. These Terms and Conditions govern your use of our website. By accessing or using our website, you agree to the terms of these Terms and Conditions.</p>
                </div>
                <div className="w-full h-auto">
                    <div className="w-[76%] max-xs:w-[90%] h-auto mx-auto pt-[2rem] seamless-gradient">
                        <p className="terms-paragraph mb-[3rem]"><span>Effective date: </span><i>26 November, 2023</i></p>
                        <h6 className="text-[2.6rem] font-dmsans font-semibold text-aimagix-dark-1d">AI Magix Private Ltd,</h6>
                        <p className="terms-paragraph">009 Bollineni hill side phase 2 Nookampalayam,  BOLLINENI HILL SIDE PHASE 2 NOOKAMPALAYAM,</p>
                        <p className="terms-paragraph mb-[5rem]">Sithalapakkam post chennai 600126, India..</p>

                        <h4 className="privacy-heading">Data Collection</h4>
                        <p className="terms-paragraph mb-[5rem]">For users authorizing during account setup and login, AI Magix gathers information from enquiry forms, contacts forms, or Microsoft Office 365, etc., for enhanced service. This includes names, emails, phone numbers, and email groups. Data is stored on Azure Cloud Services. Our legal basis for collection adheres to Art 6 (1) b) GDPR and Art. 6 (1) f) GDPR, aiming to optimize the product, improve user experience, enhance customer support, and refine internal processes.</p>
                        <h4 className="privacy-heading">Usage Information</h4>
                        <p className="terms-paragraph mb-[5rem]">AI Magix collects specific data on customer usage, including feature use, timing, and issue replays. This information includes IP addresses, browser details, URLs, errors, time zones, geolocation, and operating systems, images stored in log files. We analyze these logs to enhance application performance. Legal basis for user data collection aligns with Art 6 (1) b) GDPR and Art. 6 (1) f) GDPR.</p>
                    
                        <h4 className="privacy-heading">Azure User Data</h4>
                        <p className="terms-paragraph mb-[5rem]">We utilize Azure AD for Single Sign-On and managing access permissions</p>

                        <h4 className="privacy-heading">Cookies</h4>
                        <p className="terms-paragraph mb-[5rem]">We employ cookies for analysis and optimization, using session cookies to enhance user experience. Blocking cookies via browser settings may limit functionality.</p>

                        <h4 className="privacy-heading">Data Use</h4>
                        <p className="terms-paragraph mb-[5rem]">AI Magix utilizes collected data for service, billing, identification, service improvement, and research purposes. Customer communications may be recorded to resolve issues.</p>

                        <h4 className="privacy-heading">Service Providers</h4>
                        <p className="terms-paragraph mb-[5rem]">We engage third parties for varied services, as detailed in our <a href="">Data Processing Addendum.</a> </p>

                        <h4 className="privacy-heading">International Data Transfer</h4>
                        <p className="terms-paragraph mb-[5rem]">Data collected, including personal information, may be transferred internationally as described in this Privacy Policy.</p>

                        <h4 className="privacy-heading">Data Security</h4>
                        <p className="terms-paragraph mb-[5rem]">AI Magix employs encryption, reviews security measures, and limits access to personal information. We are in the process of getting our ISO 28001 Certification before Q1, 2024, adhering to regulatory norms.</p>

                        <h4 className="privacy-heading">Data Retention</h4>
                        <p className="terms-paragraph mb-[5rem]">We follow data minimization principles, retaining information as necessary and deleting or blocking it as per statutory provisions.</p>

                        <h4 className="privacy-heading">Your Rights</h4>
                        <p className="terms-paragraph mb-[5rem]">You have rights to access, rectify, limit processing, portability, and withdraw consent, detailed in this Privacy Policy. Contact us at <a href="">privacy@aimagix.com</a>  for queries.</p>

                        <h4 className="privacy-heading">Complaints</h4>
                        <p className="terms-paragraph mb-[5rem]">Email <a href="">privacy@aimagix.com</a>  for any data processing complaints.</p>

                        <h4 className="privacy-heading">Policy Modification</h4>
                        <p className="terms-paragraph mb-[5rem]">This Privacy Policy is subject to changes in line with legal requirements or service alterations.</p>
                        
                        <p className="terms-paragraph mb-[5rem]"><i>Last Updated: <span>27 Nov, 2023</span> </i> </p>
                    </div>

                </div>
            </div>
        </div>
    )
}
export default PrivacyPolicy;