
import './App.css';
import { Navigate, RouterProvider, createBrowserRouter } from 'react-router-dom';
import Layout from './pages/layout';
import NotFound from './pages/not-found';
import Home from './pages/home';
import BookADemo from './pages/book-a-demo';
import TermsOfService from './pages/terms-of-service';
import PrivacyPolicy from './pages/privacy-policy';
import { useState } from 'react';
function App() {
  const [isBookFromApp, setIsBookFromApp] = useState<any>(undefined)
  const router = createBrowserRouter([
    
    {
      path: "",
      element: <Layout isBookFromApp={isBookFromApp} />,
      errorElement: <NotFound />,
      children: [
        {
          path: "/",
          element: <Home handleOpenBookademo={() => setIsBookFromApp(!isBookFromApp)} />,
          index: true
        },
        {
          path: "/terms-of-service",
          element: <TermsOfService />,
        },
        {
          path: "/privacy-policy",
          element: <PrivacyPolicy />,
        },
      ]
    },
    // {
    //   path: "/book-a-demo",
    //   element: <BookADemo isBookADemofromHome={false} />,
    // }
  ]);
  return (
    <div className='w-full relative overflow-x-hidden'>
    <RouterProvider router={router}></RouterProvider>
    </div>
  );
}

export default App;
