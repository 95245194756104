import { AssemblyIcon, InspectionStationIcon, MobileSolutionsIcon, SearchIcon } from "../../svg";

function SeamlessPrecision(){
    const seamlessPrecision = [
        {
            icon: <MobileSolutionsIcon />,
            title: "Mobile Solutions",
            subTitle: "Inspections on the go."
        },
        {
            icon: <AssemblyIcon />,
            title: "Assembly Line Integration",
            subTitle: "Seamless implementation."
        },
        {
            icon: <InspectionStationIcon />,
            title: "Inspection Stations",
            subTitle: "Curated lighting for precision. "
        },
    ]
    return(
        <div className="w-full h-auto py-[10rem] seamless-gradient">
            <div className="w-[76%] h-auto mx-auto max-xs:w-[90%]">
                <div className="w-full h-auto">
                <h2 className="text-[4.6rem] max-xs:text-[3rem] font-onest font-medium text-aimagix-dark-1d text-center mb-[6.3rem]">Seamless Precision for <br /> Inspections <span className=" text-transparent bg-clip-text bg-gradient-to-r from-[#754eff] to-[#ff7a00] ">Anytime, Anywhere</span></h2>
                <div className="w-full grid grid-cols-3 gap-[4.5rem] max-xs:grid-cols-1">
                    {seamlessPrecision.map((item, index) => (
                          <div key={index} className="w-full h-auto bg-aimagix-white shadow-[0px_4px_16.5px_0px_#5C4AF636] rounded-[2.5rem] py-[5.3rem] px-[5rem] group">
                          <div className="w-full h-auto">
                              <div className="w-auto h-[9rem] mx-auto mb-[3.9rem] fill-aimagix-dark group-hover:fill-aimagix-primary">
                                {item.icon}
                              </div>
                              <div className="w-full h-auto text-center">
                                  <p className="text-[2.8rem] font-onest font-semibold text-aimagix-dark mb-[0.5rem]">{item.title}</p>
                                  <p className="text-[2rem] font-onest font-normal text-aimagix-gray-66">{item.subTitle}</p>
  
                              </div>
  
                          </div>
                      </div>
                    ))}
                  
                </div>
                </div>

            </div>
        </div>
    )
}
export default SeamlessPrecision;