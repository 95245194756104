import { useEffect, useRef } from "react";
import { Controller } from "react-hook-form";
import Select, { components, ActionMeta } from "react-select";
interface MultiSelectInputProps {
    labelName: string;
    idName: string;
    error: any;
    control: any;
    name: string;
    options: any;
    getOptionLabel?: any;
    getOptionValue?: any;
    isMulti: boolean;
    isDisabled?: boolean;
    isOptionDisabled?: any;
    isReset?: boolean;
    key?: any;
    isRequired?: boolean;
    isSearchable?: boolean;
  }

const MultiSelectInput = ({
    labelName,
    idName,
    error,
    name,
    control,
    options,
    isMulti,
    getOptionLabel,
    getOptionValue,
    isDisabled,
    isOptionDisabled,
    isReset,
    key,
    isRequired,
    isSearchable,
  }: 
  MultiSelectInputProps) => {
    const selectRef = useRef<any>(null);
    useEffect(() => {
      if (isReset) {
        selectRef?.current?.clearValue();
      }
    }, [isReset]); 
    return(
        <div className="w-full h-auto relative">
            <label htmlFor="multiSelect" className="text-[1.8rem] font-onest font-normal text-aimagix-gray-66 block mb-[1rem]">{labelName} {isRequired && <span className="text-red-500">*</span>}</label>
            <Controller
          name={name}
          render={({ field }) => (
            <Select
              {...field}
              options={options}
              classNamePrefix="cus-select"
              getOptionLabel={getOptionLabel}
              getOptionValue={getOptionValue}
              isMulti={isMulti}
              id="multipleselect"
              isClearable={false}
              menuPosition={"fixed"}
              isDisabled={isDisabled}
              isOptionDisabled={isOptionDisabled}
              ref={selectRef}
              key={key}
              isSearchable={isSearchable}
            />
          )}
          control={control}
        />
        {error && (
          <p className="text-[1.4rem] font-inter font-normal text-aimagix-red text-left absolute top-full left-0">
            {error}
          </p>
        )}
        </div>
    )
  }
  export {MultiSelectInput}