import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import IconBtn from "../components/form/icon-btn/icon-btn";
import { InputField } from "../components/form/input-field/input-field";
import { CloseIcon, CloseWhiteIcon, SearchIcon } from "../components/svg";
import GiftCode from "../components/UI/gift-code/gift-code";
import { error } from "console";
import { MultiSelectInput } from "../components/form/select-input/select-input";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchRequest } from "../services/Services";

function BookADemo({isBookADemofromHome, handleCloseModal}:any) {
    const { pathname } = useLocation();
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
    const navigate = useNavigate();

    const bookADemoFormSchema = yup.object().shape({
        firstName: yup.string().required("FirstName is required"),
        lastName: yup.string().required("LastName is required"),
        designation: yup.string().required("Designation is required"),
        emailAddress: yup
            .string()
            .email("Please Enter the Valid Email Address")
            .required("Email Address is required"),
        phNumber: yup.string().required("Phone Number is required"),
        companyName: yup.string().required("Company Name is required"),
        companyAddress: yup.string().required("Company Address is required"),
        companyBelongsTo: yup
            .array()
            .test(
                "companyBelongsTo",
                "Please select at least one options",
                (value: any) => value?.length > 0
            ),
    });
    const [appliedgiftCode, setAppliedGiftCode] = useState("");
    const [successMsg, setSuccessMsg] = useState(false);
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        setValue,
        control,
    } = useForm({ resolver: yupResolver(bookADemoFormSchema) });

    const handleFormSubmit = async (data: any) => {
        setIsLoading(true);
        // Convert companyBelongsTo array to a comma-separated string
        const companyBelongsToString = data.companyBelongsTo
            ? data.companyBelongsTo.map((option: any) => option.label).join(",")
            : "";
        const formattedData = {
            ...data,
            giftCode: appliedgiftCode,
            companyBelongsTo: companyBelongsToString,
        };
        try {
            const result = await fetchRequest(formattedData);
            if (result.success) {
                setSuccessMsg(true);
                setTimeout(() => {
                    handleCloseModal() // Navigate after 2 seconds
                }, 2000);
            } else {
                // Handle error
                console.error(result.message);
            }
        } catch (error) {
            console.error("An error occurred:", error);
        } finally {
            setIsLoading(false); // Stop loading
        }
    };

    const companyBelongsToOptions = [
        { value: "Auto OEM", label: "Auto OEM" },
        { value: "Auto Component", label: "Auto Component" },
        { value: "Auto Logistics", label: "Auto Logistics" },
        { value: "General Manufacturers", label: "General Manufacturers" },
        { value: "Other", label: "Others" },
    ];
    return (
        <div className="w-full h-screen max-xs:h-[100dvh] relative bg-aimagix-white">
            <div className="w-full h-full">
                <div className="w-[46.3rem] h-full absolute top-0 left-0 block max-xs:hidden">
                    <div className="w-full h-full book-a-demo-bg-gradient">
                        <div className="w-full h-full book-a-demo-bg"></div>
                    </div>
                </div>
                <div className="book-a-demo-main h-full ml-auto overflow-y-auto max-xs:w-full">
                    <form
                        className="w-full h-full relative"
                        onSubmit={handleSubmit((data) => handleFormSubmit(data))}
                    >
                        <div className="w-[4.8rem] h-auto fixed top-[6rem] right-[6rem] block max-xs:hidden">
                            <IconBtn
                                btnType="button"
                                icon={<CloseIcon />}
                                size="w-[4.8rem]"
                                handleClick={handleCloseModal}
                            />
                        </div>
                        {!successMsg ? (
                            <div className="w-full px-[11%] pt-[5%] pb-[2%] h-auto max-xs:px-0 max-xs:pt-0">
                                <div className="w-full h-[20vh] book-a-demo-bg-gradient hidden max-xs:block ">
                                    <div className="w-full h-full book-a-demo-bg relative">
                                        <div className="w-[4.8rem] h-auto hidden max-xs:block max-xs:ml-auto absolute top-[15%] right-[5%]">
                                           {isBookADemofromHome ?  <IconBtn
                                                btnType="button"
                                                icon={<CloseWhiteIcon />}
                                                size="w-[4.8rem]"
                                                handleClick={handleCloseModal}
                                            />:  <IconBtn
                                            btnType="button"
                                            icon={<CloseWhiteIcon />}
                                            size="w-[4.8rem]"
                                            handleClick={handleCloseModal}
                                        />}
                                           
                                        </div>
                                    </div>
                                </div>
                                <div className="w-full h-auto pb-[4rem] bg-aimagix-white border-b border-aimagix-gray-c1 pt-[4%] sticky top-[0rem] z-10 max-xs:static max-xs:pt-0 max-xs:px-[5%]">


                                    <h2 className="text-[5rem] font-onest font-semibold text-aimagix-dark leading-none mb-[0.2rem] max-xs:mt-[2rem]">
                                        Book a Demo
                                    </h2>
                                    <p className="text-[2rem] font-onest font-normal text-aimagix-gray-66">
                                        Discover why top brands in the automotive and logistics
                                        industries trust <br />{" "}
                                        <span className="text-aimagix-dark font-medium">
                                            Inspection ONE
                                        </span>{" "}
                                        with their quality processes
                                    </p>
                                </div>
                                <div className="w-full h-auto max-xs:px-[5%]">
                                    <GiftCode
                                        giftCodeValue={(value) => setAppliedGiftCode(value)}
                                        appliedgiftCode={appliedgiftCode}
                                    />
                                    <div className="w-full mb-[8rem]">
                                        <h5 className="text-[2.6rem] font-onest font-medium text-aimagix-dark mb-[4rem]">
                                            Contact Information
                                        </h5>
                                        <div className="w-full grid grid-cols-3 gap-x-[4.2rem] gap-y-[6.5rem] max-xs:grid-cols-1">
                                            <div className="w-full ">
                                                <InputField
                                                    labelName="First Name"
                                                    formHandle={register("firstName")}
                                                    id="firstName"
                                                    type="text"
                                                    isReadOnly={false}
                                                    isRequired={true}
                                                    error={errors.firstName?.message}
                                                />
                                            </div>
                                            <div className="w-full ">
                                                <InputField
                                                    labelName="Last Name"
                                                    formHandle={register("lastName")}
                                                    id="lastName"
                                                    type="text"
                                                    isReadOnly={false}
                                                    isRequired={true}
                                                    error={errors.lastName?.message}
                                                />
                                            </div>
                                            <div className="w-full ">
                                                <InputField
                                                    labelName="Designation"
                                                    formHandle={register("designation")}
                                                    id="designation"
                                                    type="text"
                                                    isReadOnly={false}
                                                    isRequired={true}
                                                    error={errors.designation?.message}
                                                />
                                            </div>
                                            <div className="w-full ">
                                                <InputField
                                                    labelName="Email Address"
                                                    formHandle={register("emailAddress")}
                                                    id="emailAddress"
                                                    type="email"
                                                    isReadOnly={false}
                                                    isRequired={true}
                                                    error={errors.emailAddress?.message}
                                                />
                                            </div>
                                            <div className="w-full ">
                                                <InputField
                                                    labelName="Phone Number"
                                                    formHandle={register("phNumber")}
                                                    id="phNumber"
                                                    type="number"
                                                    isReadOnly={false}
                                                    isRequired={true}
                                                    error={errors.phNumber?.message}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-full mb-[8rem]">
                                        <h5 className="text-[2.6rem] font-onest font-medium text-aimagix-dark mb-[4rem]">
                                            Company Information
                                        </h5>
                                        <div className="w-full grid grid-cols-3 gap-x-[4.2rem] gap-y-[6.5rem] max-xs:grid-cols-1">
                                            <div className="w-full ">
                                                <InputField
                                                    labelName="Company Name"
                                                    formHandle={register("companyName")}
                                                    id="companyName"
                                                    type="text"
                                                    isReadOnly={false}
                                                    isRequired={true}
                                                    error={errors.companyName?.message}
                                                />
                                            </div>
                                            <div className="w-full col-span-2 max-xs:col-span-1">
                                                <InputField
                                                    labelName="Company Address"
                                                    formHandle={register("companyAddress")}
                                                    id="companyAddress"
                                                    type="text"
                                                    isReadOnly={false}
                                                    isRequired={true}
                                                    error={errors.companyAddress?.message}
                                                />
                                            </div>
                                            <div className="w-full col-span-3 max-xs:col-span-1">
                                                <MultiSelectInput
                                                    labelName="Industry Your Company Belongs To"
                                                    control={control}
                                                    error={errors.companyBelongsTo?.message}
                                                    idName="companyBelongsTo"
                                                    isMulti={true}
                                                    name="companyBelongsTo"
                                                    options={companyBelongsToOptions}
                                                    getOptionLabel={(option: any) => option.label}
                                                    getOptionValue={(option: any) => option.value}
                                                    isRequired={true}
                                                    isSearchable={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-full flex justify-end items-center max-xs:mb-[5rem]">
                                        <button
                                            type="submit"
                                            className={`w-[28rem] max-xs:w-full flex justify-center items-center border border-aimagix-primary rounded-full text-[1.8rem] font-onest font-normal text-aimagix-white primary-gradient px-[1rem] py-[1rem] max-xs:py-[2rem] leading-tight ${isLoading ? "opacity-50 cursor-not-allowed" : ""
                                                }`}
                                            disabled={isLoading} // Disable button when loading
                                        >
                                            {isLoading ? (
                                               <div className="w-[2rem] h-[2rem]">
                                               <div className="btn-loader"></div>
                                             </div>
                                            ) : (
                                                "Submit"
                                            )}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="w-full h-full">
                                <div className="w-full h-full flex justify-center items-center">
                                    <div className="w-auto h-auto">
                                        <div className="w-[34.8rem] max-xs:w-[40%] h-auto mx-auto">
                                            <img
                                                src={require("../assets/images/success-msg.png")}
                                                alt="Success Msg"
                                            />
                                        </div>
                                        <p className="text-[7rem] max-xs:text-[5rem] font-onest font-semibold text-aimagix-dark-1d text-center">
                                            Thank You!
                                        </p>
                                        <p className="text-[2.2rem] max-xs:text-[1.8rem] font-onest font-normal text-aimagix-gray-66 text-center">
                                            Our team will get in touch with you shortly.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        )}
                    </form>
                </div>
            </div>
        </div>
    );
}
export default BookADemo;
