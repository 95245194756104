import { useEffect } from "react";
import { useLocation } from "react-router-dom";

function TermsOfService(){
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return(
        <div className="w-full h-auto ">
            <div className="w-full h-auto pt-[10%] max-xs:pt-[25%] relative triangle-pattern-privacy">
                <div className="w-[50%] max-xs:w-[90%] h-auto text-center mx-auto mb-[5.7rem]">
                    <h1 className="text-[8rem] max-xs:text-[5rem] font-onest font-normal text-aimagix-dark">Terms of Service</h1>
                    <p className="text-[1.8rem] font-dmsans font-normal text-aimagix-gray-66">Welcome to AI Magix. These Terms and Conditions govern your use of our website. By accessing or using <br /> our website, you agree to the terms of these Terms and Conditions.</p>
                </div>
                <div className="w-full h-auto">
                    <div className="w-[76%] max-xs:w-[90%] h-auto mx-auto pt-[2rem] seamless-gradient">
                        <p className="terms-paragraph mb-[3rem]"><span>Effective date: </span><i>26 November, 2023</i></p>
                        <p className="terms-paragraph mb-[3rem]">These Terms of Use <span>(“Terms”)</span> describe the terms under which AI Magix Private Ltd <span>(“AI Magix”, “We”, “Our”, “Us”)</span> provides a subscriber access to and use of Our Services and/or creates an Account with Us <span>(“You”, “Your”, “Yourself”)</span>. By accessing and using Our Services,</p>
                        <ul className="terms-list-ul mb-[3rem]">
                            <li>You agree to be bound by these Terms and acknowledge having read the <a href="">privacy policy</a>  <span>(“Privacy Policy”)</span>.</li>
                            <li>You warrant to Us that you have legal capacity and are competent to subscribe to Our Services</li>
                            <li>That, in the event You are entering into these Terms on behalf of any entity/company or its group, You possess the requisite authority to bind such entities, company or its groups to these Terms. If You do not agree to these Terms, You should immediately cease accessing and using Our Services.</li>
                        </ul>
                        <p className="terms-paragraph mb-[5.7rem] text-aimagix-dark-1d">You and AI Magix shall be hereinafter collectively be referred to as <span>“Parties”</span> and individually as <span>“Party”</span>.</p>
                        <h4 className="terms-heading">Description of Services</h4>
                        <ol className="terms-list-ol mb-[3rem]">
                            <li>We shall provide You with access to and utilization of
                                <ul className="terms-list-ul ml-[6rem]">
                                    <li>Our software <span>(“Platform”)</span>,</li>
                                    <li>Applications delivered via the appstore <span>(“Application”)</span> and</li>
                                    <li>consulting, implementation and/or training services <span>(“Professional Services”)</span> collectively referred to as “Service(s)”.</li>
                                </ul>
                            </li>
                            <li>Our Services are provided solely for Your business purposes subject to these Terms. You shall connect to the Platform using any internet browser supported by the Platform.You are responsible for obtaining access to the internet and the equipment necessary to access the Platform.</li>
                        </ol>
                        <h4 className="terms-heading">Your Rights</h4>
                        <ol className="terms-list-ol mb-[3rem]">
                            <li><span>License:</span> Subject to Your compliance with these Terms and solely during the Term, You and Your employees <span>“User(s)”</span> have a worldwide, non-transferable, non-exclusive, terminable, limited rights to
                            <ul className="terms-list-ul ml-[6rem]">
                                <li>To store data in, retrieve data from, or otherwise make use of (directly or indirectly) data or technology through electronic means and,</li>
                                <li>Integrate the Platform into any application and provide the Platform to Users of the application.</li>
                            </ul>
                            </li>
                            <li><span>Account:</span> You shall provide true and accurate information to create accounts or instances for access and use of the Platform (“Account”).</li>
                        </ol>
                        <h4 className="terms-heading">Your Responsibilities </h4>
                        <ol className="terms-list-ol-2 mb-[3rem]">
                            <li >Acceptable Use. You agree not to:
                            <ul className="terms-list-ul ml-[6rem]">
                                <li>license, sublicense, sell, resell, rent, lease, transfer, assign, distribute, time share, disassemble, reverse engineer, decompile or otherwise commercially exploit or make the Platform available to any third party, other than Users in furtherance of its internal business purposes as expressly permitted by this Agreement;</li>
                                <li>modify, adapt, or hack the Platform or otherwise attempt to gain unauthorized access to the Platform or related systems or networks;</li>
                                <li>use the Platform, store or transmit sensitive personal information;</li>
                                <li>use the Platform to store or transmit Your Data in violation of applicable laws and regulations, including but not limited to violation of any person's privacy rights, export control laws/regulations;</li>
                                <li>access it for purposes of creating derivative works based on, or developing or operating products or services for third parties in competition with the Platform;</li>
                                <li>use the Platform to store or transmit any content that infringes upon any person's intellectual property rights or is unlawful, racist, hateful, abusive, libelous, obscene, or discriminatory;</li>
                                <li>use the Platform to knowingly post, transmit, upload, link to, send or store any viruses, malware, trojan horses, time bombs, or any other similar harmful software;</li>
                                <li>“crawl,” “scrape,” or “spider” any page, data, or portion of or relating to the Platform (through use of manual or automated means);</li>
                                <li>reverse engineer the Platform in order to
                                    <ol className="terms-list-ol ml-[6rem]">
                                        <li>build a competitive product or service;</li>
                                        <li>build software using similar ideas, features, functions, or graphics of the Platform; and</li>
                                        <li>copy any ideas, features, functions, or graphics of the Platform.</li>
                                    </ol>
                                </li>
                                <li>leverage the user license provided to the Customer under this Agreement to develop a program for the purpose of deciphering or programmatically accessing the Platform unless prior written consent has been obtained from AI Magix.</li>
                                <li>engage in Platform abuse involving attempts to exploit security vulnerabilities to gain unauthorized access, manipulate data, or disrupt the Platform's functionality, ultimately leading to a circumvention of the Platform's intended use.</li>
                                <li>use the user license for programmatic invocation mimicking user access like behaviors through frontend screen scraping scripts, headless browser scripts, RPA tools or similar programming techniques. Such access might lead to over use of the Platform and will be considered a violation of this Agreement.</li>
                            </ul>
                            </li>
                            <li>Your Obligations
                            <ul className="terms-list-ul ml-[6rem]">
                                <li>If we inform You that a specified activity or purpose is prohibited with respect to the Platform, You have to ensure that You shall immediately cease the use of the Platform for such prohibited activity or purposes and shall comply with Our instructions in this regard.</li>
                                </ul>
                            </li>
                            <li>Payment Card Industry Data Security Standard.
                                <p className="terms-paragraph">The Platform is not optimal for storing or processing cardholder information. However, if You process any cardholder information using the Platform, You shall:</p>
                            <ul className="terms-list-ul ml-[6rem]">
                                <li>comply with the criteria defined under Payment Card Industry Data Security Standard <span> (“PCI DSS”)</span>;</li>
                                <li>implement and maintain reasonable security measures to protect all cardholder data in their possession or control; and</li>
                                <li>not take any action against Us in connection with using the Platform that places Us in non-compliance with the PCI DSS.</li>
                                </ul>
                            </li>
                        </ol>
                        <h4 className="terms-heading">Updates and availabiliity</h4>
                        <ul className="terms-list-ul ml-[6rem] mb-[4rem]">
                                <li>Any enhancements, new features, or updates <span>(“Updates”)</span> to the Platform are also subject to this Agreement, and We reserve the right to deploy Updates at any time.</li>
                                <li>The Platform may temporarily be unavailable due to scheduled downtime for upgrades and maintenance in which case We shall use commercially reasonable endeavours to notify You in advance. In the event of any unavailability of the Platform, the applicable service credits and the support provided by Us shall be in accordance with the service level and support model set forth in our contracts.</li>
                                </ul>
                        <h4 className="terms-heading">Intellectual Property Rights</h4>
                        <ul className="terms-list-ul ml-[6rem] mb-[4rem]">
                              <li>Except for the rights granted to You under clause 2, all rights, title, and interest in and to all intellectual property and/or proprietary rights, title, and interest in or related to the Services (including without limitations to all modifications, extensions, customizations, scripts or other derivative works provided, developed or delivered by Us under Professional Services), including patents, inventions, copyrights, trademarks, domain names, trade secrets or know-how <span>(collectively, “Intellectual Property Rights”)</span> shall belong to and remain exclusively with Us.</li>
                              <li>You own the rights to all electronic information, text, messages, or other content, inclusive of but not limited to Users' and end users' personal data, provided by You or Your Users via You Account while utilizing Our Services <span>(“Your Data”)</span>. We do not claim ownership over Your Data. You grant Us the right and license to use Your Data for the provision, modification, support, maintenance, and improvement of the Services.</li>
                              <li>We shall have a perpetual right and license to incorporate into the Services or otherwise use any suggestions, enhancement requests, recommendations, or other feedback it receives from You.</li>
                              <li>All rights not expressly provided to the Customer herein are reserved.</li>
                                </ul>
                        <h4 className="terms-heading">Third Party Services</h4>
                        <p className="terms-paragraph mb-[5rem]">You may integrate the Account with other third party applications or services <span>(“Third Party Service(s)”)</span>. You acknowledge and agree that its use of Third-party Service(s) will be subject to the terms and conditions and <a href="">privacy policies</a>  of such third-party and that We shall not be liable for the enablement, access, or use of such Third-party Service(s) by You, including Your data processed by such third party. You should contact the Third-party service provider for any issues arising in connection with the use of such Third-party Service(s). We try to provide the Customer with advance notice, whenever reasonably possible, You acknowledge and agree that We may, at any time and in our sole discretion, and without any notice, suspend, restrict or disable access to or remove from the Platform, any Third Party Service(s), without any liability to You, including without limitation for any loss of profits, revenue, data, goodwill or other intangible losses.</p>
                        <h4 className="terms-heading">Charges and Payment</h4>
                        <ol className="terms-list-ol-3 mb-[5rem]">
                            <li>Service Details and Pricing.
                                <p>The Services and standard pricing plan shall be as set forth as per in the contracts.</p>
                            </li>
                            <li>Charges.
                                <p>All charges associated with the Services purchased by You shall be as set forth in the applicable order form. All Charges are due in full and payable in advance by You.</p>
                            </li>
                            <li> Payment
                                <p>The Services and standard pricing plan shall be as set forth as per in the contracts.</p>
                            </li>
                            <li>Refunds
                                <p>Unless otherwise specified in these Terms, all Charges are non-refundable. No refunds shall be issued for partial use or non-use of the Services.</p>
                            </li>
                            <li>Late Payment/Non Payment of charges.
                                <p>We will notify You in the event We do not receive payment towards Charges within the due date. We must receive payments immediately from the date of the notice, failing which in addition to the right to other remedies available under law, We shall charge interest for late payment <span>@ 1.5%</span> per month on the outstanding balance of the Charges payable.</p>
                            </li>
                            <li>
                            Applicable Taxes
                            <p>Unless otherwise stated, the Charges do not include any taxes, levies, duties, or similar governmental assessments, including value-added, sales, use or withholding taxes assessable by any local, state, provincial, or foreign jurisdiction (collectively <span>“Taxes”</span>).</p>
                            </li>
                            <li>Payment Mode
                                <p>We recommend You to make payment to the Services through wire transfer or automatic clearing house <span>(“ACH”)</span>,</p>
                            </li>
                        </ol>
                        <h4 className="terms-heading">Term and Termination</h4>
                        <ol className="terms-list-ol-3 mb-[5rem]">
                            <li>Term
                                <p>The Term shall be as set forth in the applicable order form</p>
                            </li>
                            <li>Termination by You
                                <p>You may terminate Your Account in the event of any material breach committed by Us, provided You provide Us with a written notice of such breach and allow Us not less than thirty (30) days to cure such breach.</p>
                            </li>
                            <li>Termination by Us
                                <p>We also reserves the right to terminate Your Account at any time by written notice due to business reasons which shall include discontinuation of the Services.</p>
                            </li>
                            <li>Termination for Insolvency.
                                <p>Notwithstanding anything contained herein, either Party may terminate the Account and Services with notice if the other Party becomes insolvent, makes or has made an assignment for the benefit of creditors, is the subject of proceedings in voluntary or involuntary bankruptcy instituted on behalf of or against such Party (except for involuntary bankruptcies) which are dismissed within sixty (60) days, or has a receiver or trustee appointed for substantially all of its property.</p>
                            </li>
                            </ol>
                            <h4 className="terms-heading">Confidentiality; Data privacy and security</h4>
                            <ul className="terms-list-ul mb-[4rem]">
                                <li>Your Confidential Information shall include all of Your business and technological information which shall include Your Data. Our Confidential Information shall mean the Services other than Your Data. Confidential Information shall not include any information which are in the public domain (other than through a breach of these Terms), which is independently developed by the recipient, or which is received by a third party, not under restriction. The recipient will not disclose the Confidential Information, except to employees, agents, professional advisors, or third-party vendors who participate in the provision of the Services hereunder who need to know it and who have agreed to keep it confidential. The recipient will ensure that those people and entities use the received Confidential Information only to exercise the rights and fulfill obligations under this Agreement while using reasonable care to keep it confidential. The recipient may also disclose Confidential Information to the extent required by an order of a government entity of appropriate jurisdiction; provided that the recipient uses commercially reasonable efforts to promptly notify the other party of such disclosure before complying with such order.</li>
                                <li>We shall use appropriate technical and organizational measures to protect Your Data. The measures used are designed to provide a level of security appropriate to the risk of processing Your Data. We shall, without undue delay, notify You of any accidental or unlawful destruction, loss, alteration, unauthorized disclosure of, or access to Your Data processed by Us.</li>
                                <li>You acknowledge that We shall process Your Data only to provide, maintain and improve the Services, or prevent or address any technical problems, or at Your request in connection with support requests and in accordance with these Terms,<a href="">Data Processing Agreement</a>  <span>(“DPA”)</span>, and any other applicable data privacy laws and as part of the direct relationship between the Parties. We shall not process Your Data for any purposes other than what is mentioned in these Terms and and the DPA.</li>
                                <li>You understand and acknowledge that, in connection use of the Service, by You, Your Users, and/or end users, We shall process any personal data only on Your behalf and as a Data Processor (as defined in the DPA).</li>
                                <li>You acknowledge and agree that We may access or disclose information about You, Your Account, and Users, including Your Data in order to (a) comply with the law or respond to lawful requests or legal processes; or (b) prevent any infringement of group companies or its customers’ proprietary rights. Further, at Our sole discretion, any suspected fraudulent, abusive, or illegal activity by You may be referred to law enforcement authorities.</li>
                            </ul>
                            <h4 className="terms-heading">Warranties & Disclaimer</h4>
                            <p className="terms-paragraph">We warrant that Our Services will be provided according to reasonable industry standards. We exclude all express or implied representations and warranties, including any implied warranty of merchantability, fitness for a particular purpose, error-free, uninterrupted, or non-infringement.</p>
                            <ol className="terms-list-ol-3 mb-[5rem]">
                                <li>
                                Platform and Application.
                                <p className="mb-[1rem]">Except as expressly stated in these Terms, Our Platform and Application, including all server and network components, are provided on an 'as is' and 'as available' basis.</p>
                                <p>You acknowledge that We do not warrant that Your access to the Platform and Application, provided over the internet and various telecommunications networks beyond Our reasonable control, will be uninterrupted, timely, secure, error-free, or free from viruses or other malicious software.</p>
                                </li>
                                <li>Professional Services.
                                    <p className="mb-[1rem]">We are not responsible for any issues related to the performance, operation, or security of the Professional Services that arise from Your Data or Third-Party Services or professional services provided by third parties</p>
                                    <p>For any breach of the Professional Services warranty, Your exclusive remedy, and Our entire liability shall be the correction of the deficient Professional Services that caused the breach of warranty</p>
                                </li>
                            </ol>
                            <h4 className="terms-heading">Limitation of Liability </h4>
                            <p className="terms-paragraph mb-[5rem]">To the fullest extent permitted by applicable law, in no event will either Party be liable to any person for any indirect, incidental, special, punitive, cover or consequential damages (including, without limitation, damages for lost profits, lost revenue, lost sales, lost goodwill, loss of use or lost content, impact on business, business interruption, loss of anticipated savings, loss of business opportunity) however caused, under any theory of liability, including, without limitation, contract, tort, warranty, breach of statutory duty, negligence or otherwise, even if a Party has been advised as to the possibility of such damages or could have foreseen such damages.
                            </p>
                            <h4 className="terms-heading">Indemnification</h4>
                            <ol className="terms-list-ol-3 mb-[5rem]">
                                <li>Indemnification by You.
                                    <p className="mb-[1rem]">You will indemnify and hold Us harmless against any claim brought by a third party against Us, Our respective employees, officers, directors, and agents arising from Your acts or omissions in connection with Clause 3 of the Terms, provided that</p>
                                    <ul className="terms-list-ul">
                                        <li>We will promptly notify You of any threat or notice of such a claim,</li>
                                        <li>You will have the sole and exclusive control and authority to select defense attorneys, defend and/or settle any such claim; and</li>
                                        <li>We shall fully cooperate with You in connection therewith. You shall, at all times, be fully liable for all acts and omissions by Users whom You have granted access and agree to indemnify Us for all claims and losses related to the negligence or acts, omissions, or intentional misconduct of such Users.</li>
                                    </ul>
                                </li>
                                <li>Force Majeure.
                                    <p>Notwithstanding anything to the contrary contained elsewhere, We shall not be liable for unavailability of the Services caused by circumstances beyond Our reasonable control, such as but not limited to, acts of God, acts of government, acts of terror, or civil unrest, technical failures beyond AI Magix’s reasonable control (including, without limitation, inability to access the internet, unauthorized loss, distribution or dissemination of Your Data), or acts undertaken by third parties, including without limitation, distributed denial of service attacks on the Platform.</p>
                                </li>
                                <li>Publicity Rights.
                                    <p>You hereby grant AI Magix the right to identify You as AI Magix's Customer on Our websites and/or marketing collateral and to include Your use of the Services in case studies.</p>
                                </li>
                                <li>Survival
                                    <p>All clauses shall survive any termination of Your Account. Termination shall not limit either Party's liability for obligations accrued as of or prior to such termination or for any breach of these Terms.</p>
                                </li>
                                </ol>
                    </div>

                </div>
            </div>
        </div>
    )
}
export default TermsOfService;