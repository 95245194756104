const fetchRequest = async (
  data: any
) => {
  try {
    const url= "https://aimagixservices.azurewebsites.net/BookADemoAction";
    const body = JSON.stringify(data);
    const method = "POST";

    const headers = {
      'Content-Type': 'application/json',
      'apikey': '123',
    };

    const response = await fetch(url, {
      method,
      headers,
      body,
    });

    if (!response.ok) {
      return { success: false, message: "Request failed with status " + response.status };
    }
    const responseData = await response.json();
    if (["200"].includes(responseData.responseCode)) {
      return { success: true, data: responseData.responseResult };
    } else {
      const message = responseData.responseMessage?.toString().trim().replace(/^"|"$/g, '') || "Unknown error occurred";
      return { success: false, message };
    }
  } catch (error) {
    console.error("Request failed:", error);
    return { success: false, message: "An error occurred while processing the request. Please contact the administrator." };
  }
};
export { fetchRequest };
