import { useState } from "react";

interface GiftCodeProps {
    giftCodeValue: (value: string) => void;
    appliedgiftCode:any;
}

function GiftCode({giftCodeValue, appliedgiftCode}: GiftCodeProps){
    const [giftCode, setGiftCode] = useState('');

    const handleInputChange = (event:any) => {
        setGiftCode(event.target.value);
    };

    const handleApplyClick = () => {
        if (giftCode) {
            console.log('Gift Code entered:', giftCode);
            giftCodeValue(giftCode)
        }
    };

    const handleReset = () => {
        giftCodeValue("");
        setGiftCode("")
    }

    return (
        <div className="w-full h-auto pt-[5rem] pb-[8rem]">
            <p className="text-[2rem] font-onest font-normal text-aimagix-gray-66 mb-[1rem]">Gift Code</p>
            <div className="w-full flex justify-start items-center gap-[3rem] max-xs:flex-col max-xs:items-end max-xs:gap-[1rem]">
            <div className="w-[70rem] max-xs:w-full h-auto flex justify-center items-center gap-4 border border-aimagix-gray-66 rounded-full pl-[3.2rem]">
                <input
                    type="text"
                    name="enterGiftCode"
                    id="enterGiftCode"
                    placeholder="Enter Code"
                    value={giftCode}
                    onChange={handleInputChange}
                    className="flex-1 h-[6.8rem] text-[2rem] font-onest font-normal text-aimagix-dark"
                />
                <button
                    type="button"
                    className={`w-[15rem] max-xs:w-[25rem] h-[6.8rem] flex justify-center items-center text-[2rem] font-onest font-normal ${appliedgiftCode? "bg-[#23A80A]" : "bg-[#594FFC]"}   ${giftCode ? "bg-opacity-100 text-aimagix-white" : "bg-opacity-20 text-aimagix-primary"} border-none rounded-full`}
                    onClick={handleApplyClick}
                    disabled={!giftCode}  
                >
                   {appliedgiftCode !== "" ? "Applied!" : "Apply"} 
                </button>
            </div>
            {appliedgiftCode !== "" && <button type="button" className="border-none bg-none text-[2rem] font-onest text-aimagix-primary max-xs:mr-[2rem]" onClick={handleReset} >Reset</button>}
            </div>
            
        </div>
    );
}
export default GiftCode;