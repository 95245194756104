import { ReactNode } from "react";
interface IconBtnProps {
  icon: ReactNode;
  size: string;
  disabled?: boolean;
  btnType: "submit" | "reset" | "button" | undefined;
  handleClick?: (e: any) => void;
  title?: string;
  isActive?: boolean;
}
function IconBtn({
  icon,
  size,
  handleClick,
  disabled,
  btnType,
  title,
  isActive,
}: IconBtnProps) {
  return (
    <button
      disabled={disabled}
      type={btnType}
      className={`w-auto flex justify-center items-center gap-3 border-none bg-none disabled:opacity-40 disabled:cursor-default disabled:pointer-events-none ${isActive && "activeIcon"
        } `}
        onClick={(e) => (handleClick ? handleClick(e) : null)}
        title={title}
    >
      <div className={`h-auto ${size} block`}>{icon}</div>
    </button>
  );
}
export default IconBtn;