import { Quotes } from "../../svg";

function ClientFeedback(){
    
    return(
        <div className="w-full h-auto common-y-space">
            <div className="w-[76%] h-auto mx-auto max-xs:w-[90%]">
                <div className="w-full h-auto">
                    <h2 className="text-[4.6rem] font-onest font-medium text-aimagix-dark-1d text-center mb-[6rem]">Client <span className="text-transparent bg-clip-text bg-gradient-to-r from-[#754eff] to-[#ff7a00] ">Feedback</span></h2>
                </div>  
                <div className="w-full h-auto ">
                        <div className="w-full h-auto bg-[#FFF4D9] rounded-[2.4rem] p-[2rem]">
                            <div className="w-full flex justify-between items-center gap-4 max-xs:flex-col-reverse">
                                <div className="flex-1">
                                    <div className="w-full h-auto pl-[6.5rem] max-xs:mt-[2rem] max-xs:pl-0">
                                        <div className="w-[5rem] h-auto mb-[6rem]">
                                            <Quotes />
                                        </div>
                                        <h5 className="text-[2.6rem] font-onest font-semibold text-aimagix-dark-1d mb-[1.5rem]">How an Auto Logistics Partner leap forward into Automated Inspections</h5>
                                        <p className="text-[2.2rem] font-onest font-normal text-aimagix-gray-66 mb-[5.2rem]">Advanced automation of all processes is easy to set up. I cannot imagine how to manage workflows without this software.”</p>
                                        <p className="text-[2.4rem] font-onest font-medium text-aimagix-dark-1d">General Manager of VPC Site in USA</p>
                                    </div>
                                </div>
                                <div className="w-[48.2rem] h-auto max-xs:w-[100%]">
                                    <div className="w-[48.2rem] max-xs:w-[100%] h-auto">
                                        <img src={require("../../../assets/images/testimonials.png")} alt="Testimonials" />
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
            </div>
            

        </div>
    )
}
export default ClientFeedback;