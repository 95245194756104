import { Link } from "react-router-dom";

function Footer() {
    return (
        <footer className="w-full h-auto relative triangle-pattern-left">
            <div className="w-[76%] h-auto mx-auto max-xs:w-[90%]">
                <div className="w-full h-auto">
                        <div className="w-full h-auto pt-[8.4rem] pb-[6rem] border-b border-[#E9E9E9]">
                        <div className="w-full grid grid-cols-4 max-xs:grid-cols-1">
                            <div className="col-span-2 max-xs:col-span-1">
                                <div className="w-full h-auto">
                                    <div className="w-[22.8rem] h-auto mb-[1.8rem]">
                                    <img src={require("../../../assets/images/ai-magix-logo.png")} alt="AI Magix Logo" />
                                    </div>
                                    <p className="text-[2.2rem] font-dmsans font-normal text-aimagix-gray-66">40 Mn+ Inspections done so far at the rate <br /> of 100 K Inspections in a day. </p>
                                </div>
                            </div>
                            <div className="w-full">
                                <div className="w-full h-auto mt-[3rem]">
                                    <h5 className="text-[2.4rem] font-dmsans font-semibold text-aimagix-dark mb-[1.5rem]">Contact</h5>
                                    <div className="w-full h-auto">
                                        <div className="w-full h-auto flex justify-start items-center gap-[1.7rem] mb-[1rem]">
                                            <div className="w-[3rem] h-auto">
                                                <img src={require("../../../assets/images/call.png")} alt="call" />
                                            </div>
                                            <p className="text-[2.2rem] font-dmsans font-normal text-aimagix-gray-66 hover:text-aimagix-primary"><a href="tel:+919384382391">9384382391</a> </p>
                                        </div>
                                        <div className="w-full h-auto flex justify-start items-center gap-[1.7rem]">
                                            <div className="w-[2rem] h-auto">
                                                <img src={require("../../../assets/images/mail.png")} alt="mail" />
                                            </div>
                                            <p className="text-[2.2rem] font-dmsans font-normal text-aimagix-gray-66 hover:text-aimagix-primary"><a href="mailto:sales@aimagix.com">sales@aimagix.com</a> </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="w-full">
                                <div className="w-full h-auto mt-[3rem] ml-[15rem] max-xs:ml-0">
                                    <h5 className="text-[2.4rem] font-dmsans font-semibold text-aimagix-dark mb-[1.5rem]">Social</h5>
                                    <div className="w-full h-auto">
                                        <Link to="https://in.linkedin.com/company/ai-magix" target="_blank">
                                        <div className="w-[3.8rem] h-auto">
                                            <img src={require("../../../assets/images/linkedin.png")} alt="Linkedin" />
                                        </div>
                                        </Link>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-full h-auto py-[3.5rem]">
                    <div className="w-full flex justify-between items-center max-xs:flex-col max-xs:text-center ">
                        <p className="text-[1.8rem] font-dmsans font-normal text-aimagix-gray-66 max-xs:w-full max-xs:mb-[4rem]">©2024 Ai Magix    All rights reserved.</p>
                        <div className="w-auto flex justify-end items-center gap-[6rem] max-xs:w-full max-xs:justify-center">
                                <p className="text-[1.8rem] font-dmsans font-normal text-aimagix-gray-66 hover:text-aimagix-primary" ><Link to={"/terms-of-service"} >Terms of Service</Link></p>
                                <p className="text-[1.8rem] font-dmsans font-normal text-aimagix-gray-66 hover:text-aimagix-primary"><Link to={"/privacy-policy"} >Privacy Policy</Link></p>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}
export default Footer;