import { useEffect, useState } from "react";
import { SearchIcon } from "../../svg";
import { Link, useNavigate } from "react-router-dom";

function Header({handleOpenBookaDemo}:any) {
    const navigate = useNavigate()
    const [query, setQuery] = useState<any>(undefined);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newQuery = event.target.value;
        setQuery(newQuery);
    };
    const [isVisible, setIsVisible] = useState(true);
    const [lastScrollY, setLastScrollY] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > lastScrollY) {
                // Scrolling down, hide the menu
                setIsVisible(false);
            } else {
                // Scrolling up, show the menu
                setIsVisible(true);
            }
            setLastScrollY(window.scrollY);
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [lastScrollY]);

    return (
        <header className={`w-full h-auto fixed top-[4.4rem] left-0 z-50 transition-transform duration-700 ${
          isVisible ? "translate-y-0" : "translate-y-[-180%]"
      }`}>
            <div className="w-[85%] max-xs:w-[90%] h-auto mx-auto">
            {/* <div className="w-full h-auto flex justify-end items-center my-[1.6rem]">
                <div className="w-auto h-auto relative">
                    <button className="absolute top-[50%] translate-y-[-50%] left-[0.5rem] cursor-pointer z-[1] pointer-events-none">
                        <div className="w-[2rem] h-auto">
                            <SearchIcon />
                        </div>
                    </button>
                    <input
                        type="text"
                        className="w-[2.4rem]  h-auto bg-transparent text-[1.7rem] font-onest placeholder:text-aimagix-dark text-aimagix-dark font-normal py-[0.1rem] pl-[2.8rem] pr-6 border border-transparent rounded-[0.7rem] transition-all duration-300 focus:w-[23rem] focus:border-aimagix-gray-e8 focus:placeholder:text-aimagix-gray-66 relative search-icon"
                        placeholder="Search"
                        onChange={handleChange}
                        value={query}
                    />
                </div>
                <div className="w-auto">

                </div>
            </div> */}
            <div className="w-full h-auto">
                <div className="w-full h-auto  floating-menu-bg rounded-full pl-[2.6rem] pr-[1rem] py-[1rem]">
                    <div className="w-full flex justify-between items-center">
                        <Link to={"/"}>
                        <div className="w-[14.3rem] h-auto mt-[-1rem]">
                            <img src={require("../../../assets/images/ai-magix-logo.png")} alt="AI Magix Logo" />
                        </div>
                        </Link>
                        
                        <div className="w-auto flex justify-end items-center gap-[1.1rem]">
                            {/* <button type="button" className="w-[15rem] flex justify-center items-center border border-aimagix-primary rounded-full text-[1.8rem] font-onest font-normal text-aimagix-primary bg-none px-[1rem] py-[1rem] leading-tight">
                                    Login
                            </button> */}
                            <button type="button" onClick={handleOpenBookaDemo} className="w-[15rem] flex justify-center items-center border border-aimagix-primary rounded-full text-[1.8rem] font-onest font-normal text-aimagix-white primary-gradient px-[1rem] py-[1rem] leading-tight">
                            Get Started
                            </button>
                        </div>  
                    </div>
                </div>
            </div>
            </div>
            
        </header>
    )
}
export default Header;